/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";

/** services */
import api from "../services/api";
import confirm_alert from "../utils/alerts/confirm";
import error_alert from "../utils/alerts/error";
import loading_alert from "../utils/alerts/loading";
import success_alert from "../utils/alerts/success";

/** utils */

export const FormContext = React.createContext<iFormContext>({ addForm: () => {}, form: null, changeForm: () => {}, saveForm: () => {} });

export const FormProvider = (props: any) => {
    const [form, setForm] = React.useState<iForm | null>(null);

    const addForm = (data: iForm) => {
        // verifica se ja tem um formulário carregado localmente
        const hasLocalForm = localStorage.getItem("@pontualvistos_current_form");

        // se não existir então alimenta com o novo
        if (!hasLocalForm || data.id === 4) {
            setForm({ ...data, activeSection: 0 });
            localStorage.setItem("@pontualvistos_current_form", JSON.stringify({ ...data, activeSection: 0 }));
        } else {
            const dataForm = JSON.parse(hasLocalForm);

            if (dataForm.link_id !== data.link_id) {
                setForm({ ...data, activeSection: 0 });
                localStorage.setItem("@pontualvistos_current_form", JSON.stringify({ ...data, activeSection: 0 }));
            } else {
                setForm(JSON.parse(hasLocalForm));
            }
        }
    };

    const removeForm = () => {
        localStorage.removeItem("@pontualvistos_current_form");
        setForm(null);
    };

    const changeForm = (data: iForm) => {
        let _record: any = form;

        const currentactiveSection = _record?.activeSection ?? 0;

        // verifica se está passando de secao, para validar os campso obrigatórios.
        if ((data.activeSection ?? 0) > currentactiveSection && _record && _record?.sections) {
            _record?.sections[currentactiveSection].fields.map((field: iField) => {
                if (field.is_required && field.show !== false) {
                    if (!field.values?.value || field.values?.value === "") {
                        data.activeSection = currentactiveSection;

                        error_alert(`Campo obrigatório.`, `O campo "${field.name}" é obrigatório.`, () =>
                            document.getElementById(`field-${field.id}`)?.focus(),
                        );

                        return null;
                    }
                }

                return null;
            });
        }

        _record = { ...form, ...data };
        setForm(_record);
        localStorage.setItem("@pontualvistos_current_form", JSON.stringify(_record));
    };

    const saveForm = async () => {
        if (!form || !form.sections) return;

        const confirmSend = await confirm_alert("Deseja enviar seus dados?");

        if (!confirmSend) return;

        loading_alert("Enviando formulário...", "Por favor aguarde...");

        try {
            const answers: any[] = [];

            form.sections
                .filter(i => i.show !== false)
                .map(sec =>
                    sec.fields
                        .filter(i => i.show !== false)
                        .map(fil => {
                            if (fil.values) {
                                answers.push({
                                    section_id: sec.id,
                                    form_id: form.id,
                                    question_id: fil.values.field_id,
                                    answer: fil.values.value,
                                    note: fil.values?.note ?? "",
                                });
                            }
                        }),
                );

            await api.post(`/forms/answers`, { answers, link_id: form.link_id, site_id: form.site_id });

            if (form.id === 4) {
                await success_alert("Indicação enviada com sucesso.", "Os dados da indicação foram enviados com sucesso!");

                return document.location.reload();
            } else {
                success_alert("Solicitação enviada com sucesso.", "Seus dados foram enviados com sucesso!");
            }

            removeForm();
        } catch (e: any) {
            console.log("erro ao cadastrar", e);

            error_alert(
                "Não foi possível enviar sua solicitação",
                e?.response?.data?.errors ? e?.response?.data?.errors[0].message : e?.response?.data ?? "Tente novamente",
            );
        }
    };

    const value = useMemo(() => ({ form, addForm, changeForm, saveForm }), [setForm, addForm, changeForm]);

    React.useEffect(() => {}, []);

    return <FormContext.Provider value={value}>{props.children}</FormContext.Provider>;
};

export const useForm = () => React.useContext<iFormContext>(FormContext);
