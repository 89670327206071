import swal from "sweetalert";

export default function success_alert(title = "Sucesso", msg: string = "", btnText = "Fechar") {
    if (swal && swal.getState && swal.getState().isOpen && swal.close) swal.close();

    return new Promise(resolve => {
        swal({
            title,
            text: msg,
            icon: "success",
            buttons: {
                ok: { text: btnText, value: 1 },
            },
            dangerMode: false,
            closeOnClickOutside: true,
            closeOnEsc: true,
            timer: 5000,
        }).then(confirmation => {
            resolve(confirmation);
        });
    });
}
